import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const UserDataForm = ({errors, onSubmitCallback, readOnly, setReadOnly}) => {
    
    const bot1 = "Quiero actualizar mis datos";
    const bot2 = "Guardar";

    const userDefault = {
        firstName: "",
        lastName: "",
        phone: ""
    }

    const [buttonText, setButtonText] = useState(bot1);
    //const [readOnly, setReadOnly] = useState(true);
    const [userThis, setUserThis] = useState(userDefault);
    
    const user = useSelector( state => state.sesion.user );

    useEffect(() => {
        readOnly? setButtonText(bot1): setButtonText(bot2);
    }, [readOnly]);

    useEffect( () => {
        if (user.uuid) {
            //console.log("FormUsuario.useEffect [] storeUsuario:");
            //console.log(storeUsuario);
            setUserThis({
                ...user,
                firstName: user.firstName? user.firstName: "",
                lastName: user.lastName? user.lastName: "",
                phone: user.phone? user.phone: ""
            });
        }
        // eslint-disable-next-line
    }, [user]);

    const cancel = () => {
        setReadOnly(true);
        setUserThis(user);
    }

    const submitForm = (e) => {
        e.preventDefault();

        //console.log("UserDataForm userThis:");
        //console.log(userThis);

        onSubmitCallback(userThis); // save or update user data
        //if (!readOnly) onSubmitCallback(userThis); // save or update user data
        //setReadOnly(!readOnly); // change the state
    }

    const changeObject = e => {
    
        let newValue = e.target.value;
        const [firstProp, ...otherProps] = e.target.name.split('.');
       
        if (!otherProps.length) {
            setUserThis({
              ...userThis,
              [e.target.name] : newValue
            })
        } else {
          const nestedObject = {...userThis[firstProp]};
          otherProps.reduce(
              // eslint-disable-next-line
              (oldValue, val, index) => {
                  if (index < otherProps.length - 1) {
                    return oldValue[val];
                  }
                  oldValue[val] = newValue;
              },
              nestedObject
          );
        
          setUserThis({
            ...userThis,
            [firstProp]: nestedObject
          })
        }
    }

    return (
    <>
        <Form onSubmit={submitForm}>
            <Row>
                <Col md='6' xs='12'></Col>
                <Col md='6' xs='12'></Col>
            </Row>
            <Form.Group control="firstName">
                <Form.Label>Nombre</Form.Label>
                <Form.Control //type='text' 
                    name="firstName"
                    value={userThis.firstName} 
                    onChange={changeObject}
                    placeholder='Ingresa el nombre'
                    isInvalid={errors.firstName}
                    readOnly={readOnly}
                />
                <Form.Control.Feedback type='invalid'>{errors.firstName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="lastName" className='mt-3'>
                <Form.Label>Apellido</Form.Label>
                <Form.Control //type='text' 
                    name="lastName"
                    value={userThis.lastName} 
                    onChange={changeObject}
                    placeholder='Ingresa el apellido'
                    isInvalid={errors.lastName}
                    readOnly={readOnly}
                />
                <Form.Control.Feedback type='invalid'>{errors.lastName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="phone" className='mt-3'>
                <Form.Label>Teléfono <small>(poner código de país)</small></Form.Label>
                <InputGroup>
                    <Form.Control //type='text' 
                        name="phone"
                        value={userThis.phone} 
                        onChange={changeObject}
                        placeholder='Ingresa el teléfono'
                        isInvalid={errors.phone}
                        readOnly={readOnly}
                    />
                    <Button variant='success'
                        title='Probar el número'
                        as={NavLink} 
                        to={`https://api.whatsapp.com/send?phone=${userThis.phone}&text=Mensaje%20de%20prueba%20.%20Embudi.`}
                    >
                        <span className="icon is-small">
                            <i className="fa-brands fa-whatsapp" />
                        </span>
                        <span className='ml-0.5'>Probar número</span>
                    </Button>
                </InputGroup>
                <Form.Control.Feedback type='invalid'>{errors.phone}</Form.Control.Feedback>
            </Form.Group>

            <Button variant='primary' type='submit' className='mt-4'>{buttonText}</Button>
            
            { !readOnly &&
                <Button variant='secondary' onClick={() => cancel() } className='mt-4 ml-1'>Cancelar</Button>
            }
                
            
            
            
        </Form>
    </>
    );
}
 
export default UserDataForm;