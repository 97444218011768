import React from 'react';
import { Button, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { PATH_HOME, PATH_LOGIN, PATH_MY_PRODUCTS, PATH_USER } from '../../utils/path';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUsuario } from '../../actions/sesionAction';

const Header = () => {

    const logged = useSelector( state => state.sesion.logged );
    const user = useSelector( state => state.sesion.user );

    const dispatch = useDispatch();

    const closeSession = () => {
        console.log("Close session");
        dispatch(logoutUsuario());
    }

    return (
        <>
            <Navbar 
                expand='lg' 
                className="bg-body-tertiary shadow mb-4 bg-white rounded"
                //style={{position: 'sticky'}}
            >
            <Container fluid>
                <Navbar.Brand as={NavLink} to={PATH_HOME} className='ml-3'><img alt='Ir al inicio' src='/logo 790x211.png' style={{ width:'10rem' }} /></Navbar.Brand>
                <Navbar.Toggle aria-controls='main-menu'></Navbar.Toggle>
                <Navbar.Collapse id='main-menu'>
                    <Nav className='ms-auto'>
                        { !logged &&
                            <Nav.Link as={NavLink} to={PATH_LOGIN}>Iniciar Sesión</Nav.Link>
                        }
                        <Link to={PATH_HOME}> {/*This way (Link and not as={NavLink}) the property variant='outline-primary' works*/}
                            <Button variant="outline-primary" className='mr-1'>Inicio</Button>
                        </Link>
                        {/*<Nav.Link>Publicar</Nav.Link>*/}
                    </Nav>
                    
                    { logged &&
                    <Nav>
                        <NavDropdown title={user.shortName} id='menu-dropdown' className='mr-5'>
                            <NavDropdown.Item as={NavLink} to={PATH_MY_PRODUCTS}>Mis Propiedades</NavDropdown.Item>
                            {/*<NavDropdown.Item>Guardados</NavDropdown.Item>*/}
                            <NavDropdown.Item as={NavLink} to={PATH_USER} >Mis datos</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => closeSession()} >Cerrar sesión</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    }
                </Navbar.Collapse>
            </Container>
            </Navbar>

        </>
    );
}
 
export default Header;