import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Foot = () => {
    return (
        <>
            
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            <Navbar className='justify-content-center' bg='dark' variant='dark' expand='lg'>
                <Navbar.Text>
                    {'Developed by '}
                    <Link to={'https://www.linkedin.com/in/ronny-v-02464062/'} target='blank'>
                        Ronny Valles
                    </Link>
                </Navbar.Text>
            </Navbar>
        </>
    );
}
 
export default Foot;