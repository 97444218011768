import React, { useEffect, useState } from 'react';
import { Alert, CardGroup, Col, Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loadMyProducts } from '../../actions/productAction';
import ProductHomeCard from './ProductHomeCard';

const Home = () => {

    const dispatch = useDispatch();
    const productList = useSelector ( state => state.product.productList );
    const pageNumber = useSelector ( state => state.product.pageNumber );
    //const pageSize = useSelector ( state => state.product.pageSize );
    const totalPages = useSelector ( state => state.product.totalPages );
    const productFilters = useSelector ( state => state.product.productFilters );

    useEffect(() => {
        updateProductList(0);
    // eslint-disable-next-line
    }, []);

    const [items, setItems] = useState([]);
    useEffect(() => {
        // get an array of 'totalPages' size , the content doesn't matter.
        // I need use it to render the pagination buttons: items.map ( <Pagination.Item ...)
        if (productList && productList.length>0 && items.length === 0) {
            let vec = [];
            for (let number = 1; number <= totalPages; number++) {
                vec.push(number);
            }
            setItems(vec);
        }
    // eslint-disable-next-line
    }, [productList]);

    const updateProductList = (numPag) => {
        //console.log("MyProductTable.updateProductList numPag:", numPag);
        //setPageNumber(numPag);
        dispatch(loadMyProducts(undefined, numPag, productFilters))
            .then(response => {
                //console.log("MyProductTable.useEffect[user] response: ", response);
            }).catch(error => {
                console.log("MyProductTable.useEffect[user] error: ", error);
            });
    }

if (productList.length === 0) return (
    <>
        <br/><br/>
        <Alert variant='info'>
            No se encuentran propiedades
        </Alert>
    </>
);

return (<>
    <section id='list-section'></section>
    <br/>
    <CardGroup>
        {
            productList.map( (p, i) => (
                <Col xxl={3} xl={4} lg={6} md={12} sm={12} key={i} 
                    //className='mb-4'
                    //style={{padding: '1rem'}}
                    className='galery-col d-flex align-items-center justify-content-center'
                >
                    <ProductHomeCard product={p}/>
                </Col>
            ))
        }
    </CardGroup>

    <br/>
    <nav className='d-flex justify-content-center'>
        <Pagination>
        {
            items.map( (i, index) => (
                <Pagination.Item key={index} 
                    active={index === pageNumber} 
                    onClick={() => updateProductList(index)}
                >
                    {index+1}
                </Pagination.Item>
            ))
        }
        </Pagination>
    </nav>
</>);
}
 
export default Home;