import React, { useEffect, useState } from 'react';
import { Badge, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { productCreate, productOperationList, productStatusList, productStratumList, productTypeList, productUpdate } from '../../actions/productAction';
import { isNull, getEntireNumbersList, getYesNoList, noSelected } from '../../utils/functions';
import Swal from 'sweetalert2';
import { SELECT } from '../../const/actionType';

const ProductCreateForm = () => {
    
    const bot1 = "Quiero actualizar los datos";
    const bot2 = "Guardar";
    const dispatch = useDispatch();

    const productDefault = {
        productId: null, 
        address: "",
        bathrooms: 0,
        bedrooms: 0,
        city: {cityId: 1, name: 'Cuenca', code: 'cue', enable: true}, 
        code: "",
        comments: "", 
        commission: 0,
        coordinates: "", 
        exclusivity: false, 
        landArea: 0, 
        livableArea: 0, 
        longDescription: "", 
        publicPrice: 0,
        minPrice: 0,
        name: "", 
        operation: -1, 
        ownerName: "",  
        ownerPhone: "", 
        parkingSpots: 0,
        reference: "", 
        sector: "", 
        status: "Available", 
        stratum: -1, 
        type: { productTypeId: -1 }, 
        user: null

    }

    const [buttonText, setButtonText] = useState(bot1);
    const [readOnly, setReadOnly] = useState(false);
    const [product, setProduct] = useState(productDefault);
    const [errors, setErrors] = useState({});
    
    const user = useSelector(state => state.sesion.user);
    const productReducer = useSelector(state => state.product.product);

    useEffect(() => {
        readOnly? setButtonText(bot1): setButtonText(bot2);
    }, [readOnly]);

    useEffect( () => {
        if (user && user.uuid) {
            setProduct({
                ...product,
                user: user
            });
        }
        // eslint-disable-next-line
    }, [user]);

    useEffect( () => {
        if (productReducer && productReducer.productId) {
            setProduct({
                ...productReducer
            });
            setReadOnly(true);
        } else {
            setProduct({
                ...productDefault
            });
            setReadOnly(false);
        }
        // eslint-disable-next-line
    }, [productReducer]);

    const submitForm = async(e) => {
        e.preventDefault();
        if (readOnly) {
            setReadOnly(false); // change the state
        } else {
             await createOrUpdate(); // save or update user data
        }
    }

    const createOrUpdate = async() => {
        //console.log("ProductCreateForm.createOrUpdate product:", product);

        const errors = {};
        let ok = true;

        if (noSelected(product.type.productTypeId)) { ok = false; errors.type = "Selecciona el tipo"; }
        if (noSelected(product.status)) { ok = false; errors.status = "Selecciona el tipo"; }
        if (noSelected(product.operation)) { ok = false; errors.operation = "Selecciona la operación"; }
        if (isNull(product.name)) { ok = false; errors.name = "Ingresa el título"; }
        else if (product.name.length > 250) { ok = false; errors.name = "El título tiene " + product.name.length + " caracteres, debe tener máximo 250"; }
        if (isNull(product.publicPrice)) { ok = false; errors.publicPrice = "Ingresa el precio al público"; }
        if (isNull(product.ownerName)) { ok = false; errors.ownerName = "Ingresa el nombre del propietario (a)"; }
        else if (product.ownerName.length > 100){ ok = false; errors.ownerName = "El nombre del propietario tiene " + product.ownerName.length + " caracteres, debe tener máximo 100"; }
        if (isNull(product.longDescription)) { ok = false; errors.longDescription = "Ingresa la descripción larga"; }
        else if (product.longDescription.length > 3000) { ok = false; errors.longDescription = "La descripción larga tiene " + product.longDescription.length + " caracteres, debe tener máximo 3000"; }

        if (product.reference.length > 500) { ok = false; errors.reference = "La referencia tiene " + product.reference.length + " caracteres, debe tener máximo 500"; }
        if (product.comments.length > 3000) { ok = false; errors.comments = "Los comentarios tienen " + product.reference.length + " caracteres, deben tener máximo 3000"; }
        if (product.code.length > 20) { ok = false; errors.code = "El código tiene " + product.code.length + " caracteres, debe tener máximo 20"; }
        if (product.sector.length > 50) { ok = false; errors.sector = "El sector tiene " + product.sector.length + " caracteres, debe tener máximo 50"; }
        if (product.address.length > 100) { ok = false; errors.address = "La dirección tiene " + product.address.length + " caracteres, debe tener máximo 100"; }

        setErrors(errors);

        if (ok) {
            if (product.productId) {
                dispatch(productUpdate(await getProduct(product)))
                    .then(response => {
                        console.log("ProductCreateForm.submitForm productUpdate.response:", response);
                    }).catch(error => {
                        console.log("ProductCreateForm.submitForm productUpdate.error:", error);
                    });
            } else {
                dispatch(productCreate(await getProduct(product)))
                    .then(response => {
                        console.log("ProductCreateForm.submitForm productCreate.response:", response);
                    }).catch(error => {
                        console.log("ProductCreateForm.submitForm productCreate.error:", error);
                    });
            }
            setReadOnly(true); // change the state
        } else {
            Swal.fire({icon: "error", title: "Error", text: "Revisar los datos", toast: true});
        }
    }

    const getProduct = async() => {

        let tmp = {
            ...product,
            user: user
        };

        if (noSelected(tmp.exclusivity)) tmp.exclusivity = false;
        if (noSelected(tmp.operation)) delete tmp.operation;
        if (noSelected(tmp.status)) tmp.status = "Available";
        if (noSelected(tmp.stratum)) delete tmp.stratum;
        
        //if (tmp.availableDate.trim() === "") delete tmp.availableDate;
        return tmp;
    }

    const changeObject = e => {
    
        let newValue = e.target.value;
        const [firstProp, ...otherProps] = e.target.name.split('.');
       
        if (!otherProps.length) {
            setProduct({
              ...product,
              [e.target.name] : newValue
            })
        } else {
          const nestedObject = {...product[firstProp]};
          otherProps.reduce(
              // eslint-disable-next-line
              (oldValue, val, index) => {
                  if (index < otherProps.length - 1) {
                    return oldValue[val];
                  }
                  oldValue[val] = newValue;
              },
              nestedObject
          );
        
          setProduct({
            ...product,
            [firstProp]: nestedObject
          })
        }
    }

    const cancel = () => {
        setReadOnly(true);
        setProduct(productReducer);
    }

    return (
    <>
        <Form onSubmit={submitForm}>
            
            {/* the same at the end */}
            <Button variant='primary' type='submit' className='mt-4'>{buttonText}</Button>
            
            { // the same at the end
                !readOnly && product.productId && // if is a new product so hide this button
                <>
                    <Button variant='secondary' onClick={() => cancel() } className='mt-4 ml-1'>Cancelar</Button>
                </>
            }

            <Form.Group control="type" className='mt-3'>
                <Form.Label>Tipo</Form.Label>
                <Form.Select 
                    aria-label="Default select example" 
                    name="type.productTypeId"
                    value={product.type.productTypeId}
                    isInvalid={errors.type}
                    disabled={readOnly}
                    onChange={changeObject}>
                    {
                        productTypeList(SELECT).map(t =>(
                            <option value={t.value} key={t.value}>{t.label}</option>
                        ))
                    }
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errors.type}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="status" className='mt-3'>
                <Form.Label>Estado</Form.Label>
                <Form.Select 
                    aria-label="Default select example" 
                    name="status"
                    value={product.status}
                    isInvalid={errors.status}
                    disabled={readOnly}
                    onChange={changeObject}>
                    {
                        productStatusList(SELECT).map(s =>(
                            <option value={s.value} key={s.value}>{s.label}</option>
                        ))
                    }
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errors.status}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="operation" className='mt-3'>
                <Form.Label>Operación</Form.Label>
                <Form.Select 
                    aria-label="Default select example" 
                    name="operation"
                    value={product.operation}
                    isInvalid={errors.operation}
                    disabled={readOnly}
                    onChange={changeObject}>
                    {
                        productOperationList(SELECT).map(o =>(
                            <option value={o.value} key={o.value}>{o.label}</option>
                        ))
                    }
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errors.operation}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="name" className='mt-3'>
                <Form.Label>Título</Form.Label>
                <Form.Control //type='text' 
                    name="name"
                    value={product.name} 
                    onChange={changeObject}
                    placeholder='Ingresa el nombre o título de la propiedad'
                    isInvalid={errors.name}
                    readOnly={readOnly}
                />
                <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="longDescription" className='mt-3'>
                <Form.Label>Descripción larga</Form.Label>
                <Form.Control type='text' as="textarea" rows={3}
                    name="longDescription"
                    value={product.longDescription} 
                    onChange={changeObject}
                    placeholder='Ingresa una descripción larga'
                    isInvalid={errors.longDescription}
                    readOnly={readOnly}
                />
                <Form.Control.Feedback type='invalid'>{errors.longDescription}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="stratum" className='mt-3'>
                <Form.Label>Estrato</Form.Label>
                <Form.Select 
                    aria-label="Default select example" 
                    name="stratum"
                    value={product.stratum}
                    isInvalid={errors.stratum}
                    disabled={readOnly}
                    onChange={changeObject}>
                    {
                        productStratumList(SELECT).map(s =>(
                            <option value={s.value} key={s.value}>{s.label}</option>
                        ))
                    }
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errors.stratum}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="landArea" className='mt-3'>
                <Form.Label>Área del terreno (m2)</Form.Label>
                <Form.Control //type='text' 
                    name="landArea"
                    value={product.landArea} 
                    onChange={changeObject}
                    placeholder='Ingresa el área en metros cuadrados'
                    isInvalid={errors.landArea}
                    readOnly={readOnly}
                />
                <Form.Control.Feedback type='invalid'>{errors.landArea}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="livableArea" className='mt-3'>
                <Form.Label>Área de construcción (m2)</Form.Label>
                <Form.Control //type='text' 
                    name="livableArea"
                    value={product.livableArea} 
                    onChange={changeObject}
                    placeholder='Ingresa el área en metros cuadrados'
                    isInvalid={errors.livableArea}
                    readOnly={readOnly}
                />
                <Form.Control.Feedback type='invalid'>{errors.livableArea}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="bathrooms" className='mt-3'>
                <Form.Label>Baños</Form.Label>
                <Form.Select 
                    aria-label="Default select example" 
                    name="bathrooms"
                    value={product.bathrooms}
                    disabled={readOnly}
                    onChange={changeObject}>
                    {
                        getEntireNumbersList(undefined, 0, 8).map( num =>(
                            <option value={num} key={num}>{num}</option>
                        ))
                    }
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errors.bathrooms}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="bedrooms" className='mt-3'>
                <Form.Label>Habitaciones</Form.Label>
                <Form.Select 
                    aria-label="Default select example" 
                    name="bedrooms"
                    value={product.bedrooms}
                    disabled={readOnly}
                    onChange={changeObject}>
                    {
                        getEntireNumbersList(undefined, 0, 8).map( num =>(
                            <option value={num} key={num}>{num}</option>
                        ))
                    }
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errors.bedrooms}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="parkingSpots" className='mt-3'>
                <Form.Label>Estacionamientos</Form.Label>
                <Form.Select 
                    aria-label="Default select example" 
                    name="parkingSpots"
                    value={product.parkingSpots}
                    disabled={readOnly}
                    onChange={changeObject}>
                    {
                        getEntireNumbersList(undefined, 0, 8).map( num =>(
                            <option value={num} key={num}>{num}</option>
                        ))
                    }
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errors.parkingSpots}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="sector" className='mt-3'>
                <Form.Label>Sector</Form.Label>
                <Form.Control //type='text' 
                    name="sector"
                    value={product.sector} 
                    onChange={changeObject}
                    placeholder='Ingresa el sector del bien raíz'
                    isInvalid={errors.sector}
                    readOnly={readOnly}
                />
                <Form.Control.Feedback type='invalid'>{errors.sector}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="publicPrice" className='mt-3'>
                <Form.Label>Precio al público</Form.Label>
                <Form.Control //type='text' 
                    name="publicPrice"
                    value={product.publicPrice} 
                    onChange={changeObject}
                    placeholder='Ingresa el teléfono'
                    isInvalid={errors.publicPrice}
                    readOnly={readOnly}
                />
                <Form.Control.Feedback type='invalid'>{errors.publicPrice}</Form.Control.Feedback>
            </Form.Group>
            
            <br/>
            <hr/>
            <h5><Badge bg="secondary">Los datos a continuación son solo para el vendedor</Badge></h5>

            <Form.Group control="minPrice" className='mt-3'>
                <Form.Label>Precio mínimo</Form.Label>
                <Form.Control //type='text' 
                    name="minPrice"
                    value={product.minPrice} 
                    onChange={changeObject}
                    placeholder='Ingresa el teléfono'
                    isInvalid={errors.minPrice}
                    readOnly={readOnly}
                />
                <Form.Control.Feedback type='invalid'>{errors.minPrice}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="exclusivity" className='mt-3'>
                <Form.Label>Contrato de exclusividad</Form.Label>
                <Form.Select 
                    aria-label="Default select example" 
                    name="exclusivity"
                    value={product.exclusivity}
                    isInvalid={errors.exclusivity}
                    disabled={readOnly}
                    onChange={changeObject}>
                    {
                        getYesNoList(SELECT).map(item =>(
                            <option value={item.value} key={item.value}>{item.label}</option>
                        ))
                    }
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errors.exclusivity}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="code" className='mt-3'>
                <Form.Label>Código</Form.Label>
                <Form.Control //type='text' 
                    name="code"
                    value={product.code} 
                    onChange={changeObject}
                    placeholder='Ingresa el código'
                    isInvalid={errors.code}
                    disabled={readOnly}
                    readOnly={readOnly}
                />
                <Form.Control.Feedback type='invalid'>{errors.code}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="reference" className='mt-3'>
                <Form.Label>Referencia (de la ubicación)</Form.Label>
                <Form.Control //type='text' 
                    name="reference"
                    value={product.reference} 
                    onChange={changeObject}
                    placeholder='Ingresa la referencia'
                    isInvalid={errors.reference}
                    readOnly={readOnly}
                />
                <Form.Control.Feedback type='invalid'>{errors.reference}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="ownerName" className='mt-3'>
                <Form.Label>Nombre de Propietario (a)</Form.Label>
                <Form.Control //type='text' 
                    name="ownerName"
                    value={product.ownerName} 
                    onChange={changeObject}
                    placeholder='Ingresa el nombre de propietario (a)'
                    isInvalid={errors.ownerName}
                    readOnly={readOnly}
                />
                <Form.Control.Feedback type='invalid'>{errors.ownerName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="ownerPhone" className='mt-3'>
                <Form.Label>Teléfono propietario (a)</Form.Label>
                <Form.Control //type='text' 
                    name="ownerPhone"
                    value={product.ownerPhone} 
                    onChange={changeObject}
                    placeholder='Ingresa el teléfono del propietario (a)'
                    isInvalid={errors.ownerPhone}
                    readOnly={readOnly}
                />
                <Form.Control.Feedback type='invalid'>{errors.ownerPhone}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="commission" className='mt-3'>
                <Form.Label>Porc. de comisión</Form.Label>
                <Form.Control //type='text' 
                    name="commission"
                    value={product.commission} 
                    onChange={changeObject}
                    placeholder='Ingresa el % de comisión'
                    isInvalid={errors.commission}
                    readOnly={readOnly}
                />
                <Form.Control.Feedback type='invalid'>{errors.commission}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="comments" className='mt-3'>
                <Form.Label>Comentarios</Form.Label>
                <Form.Control type='text' as="textarea" rows={3}
                    name="comments"
                    value={product.comments} 
                    onChange={changeObject}
                    placeholder='Ingresa comentarios de ayuda (solo vendedor)'
                    isInvalid={errors.comments}
                    readOnly={readOnly}
                />
                <Form.Control.Feedback type='invalid'>{errors.comments}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="coordinates" className='mt-3'>
                <Form.Label>Coordenadas</Form.Label>
                <Form.Control //type='text' 
                    name="coordinates"
                    value={product.coordinates} 
                    onChange={changeObject}
                    placeholder='Ingresa las coordenadas'
                    isInvalid={errors.coordinates}
                    readOnly={readOnly}
                />
                <Form.Control.Feedback type='invalid'>{errors.coordinates}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group control="address" className='mt-3'>
                <Form.Label>Dirección</Form.Label>
                <Form.Control //type='text' 
                    name="address"
                    value={product.address} 
                    onChange={changeObject}
                    placeholder='Ingresa la dirección'
                    isInvalid={errors.address}
                    readOnly={readOnly}
                />
                <Form.Control.Feedback type='invalid'>{errors.address}</Form.Control.Feedback>
            </Form.Group>

            {/* the same at the begining */}
            <Button variant='primary' type='submit' className='mt-4'>{buttonText}</Button>
            
            { // the same at the begining
                !readOnly && product.productId && // if is a new product so hide this button
                <>
                    <Button variant='secondary' onClick={() => cancel() } className='mt-4 ml-1'>Cancelar</Button>
                </>
            }
        </Form>
    </>
    );
}
 
export default ProductCreateForm;